/* global */

body {
  background: #2c3e50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4ca1af,
    #2c3e50
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4ca1af,
    #2c3e50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.custom-container {
  margin-top: 2%;
  margin-left: 6.5%;
  margin-right: 6.5%;
}

h1.page-header {
  text-align: center;
  color: white;
  font-family: "Fjalla One", sans-serif;
  font-size: 3.3em;
  margin-top: 2.5em;
  margin-bottom: 0;
}

/* navbar */

nav#navbar-style {
  background: #2c3e50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4ca1af,
    #2c3e50
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4ca1af,
    #2c3e50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding-left: 1.5%;
  padding-right: 1.5%;
}

@media screen and (max-width: 1000px) {
  nav#navbar-style {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }
}

a.navbar-custom-links {
  color: rgba(255, 255, 255, 0.5);
  /* margin-left: 1em; */
  /* transition: 0.5s; */
}

a.navbar-custom-links:hover {
  color: rgb(218, 218, 218);
  font-style: normal;
  text-decoration: none;
}

/* landing page */

h1.home-page-header {
  text-align: center;
  color: white;
  font-family: "Fjalla One", sans-serif;
  font-size: 5em;
  margin-top: 5em;
  margin-bottom: 0;
}

@media screen and (max-width: 950px) {
  h1.home-page-header {
    font-size: 3.8em;
    margin-top: 3em;
    margin-bottom: 20px;
  }
}

p.home-page-subtext {
  margin-top: 0;
  text-align: center;
  color: white;
  font-family: "Gelasio", serif;
  font-size: 1.5em;
}

@media screen and (max-width: 950px) {
  p.home-page-subtext {
    font-size: 1em;
  }
}

p.home-page-subtext2 {
  margin-top: 6em;
  text-align: center;
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 1em;
}

@media screen and (max-width: 950px) {
  p.home-page-subtext2 {
    margin-top: 6em;
    font-size: 0.9em;
  }
}

p.home-page-subtext3 {
  text-align: center;
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 1em;
}

@media screen and (max-width: 950px) {
  p.home-page-subtext3 {
    font-size: 0.9em;
  }
}

a#landing-page-link {
  color: rgb(255, 229, 159);
  transition: 0.5s;
}

a#landing-page-link:hover {
  color: white;
  text-decoration: none;
}

img#landing-page-img {
  width: 30em !important;
  margin: auto;
}

/* portfolio page */

div#portfolio-div {
  margin-left: 20%;
  margin-right: 20%;
}

p#portfolio-subtext {
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  text-align: center;
  color: white;
}

div.article-div {
  margin-bottom: 5em;
}

@media screen and (max-width: 1200px) {
  div#portfolio-div {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media screen and (max-width: 900px) {
  div#portfolio-div {
    margin-left: 2%;
    margin-right: 2%;
  }
}

h2.portfolio-title {
  font-style: italic;
  text-align: left;
  margin-top: 3em;
  margin-bottom: 10px;
  font-size: 2em;
  font-family: "Fjalla One", sans-serif;
}

a#portfolio-title-link {
  color: rgb(255, 237, 188);
  transition: 0.3s;
}

a#portfolio-title-link:hover {
  color: white;
  text-decoration: none;
}

a#portfolio-publisher-link {
  color: rgb(255, 237, 188);
  transition: 0.3s;
}

a#portfolio-publisher-link:hover {
  color: white;
  text-decoration: none;
}

p.portfolio-content {
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  color: rgb(255, 255, 255);
  font-weight: 100;
  margin-bottom: 2em;
}

p.portfolio-content2 {
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  text-align: justify;
  color: rgb(231, 231, 231);
  font-weight: 100;
}

/* blog page */

div#blog-div {
  margin-left: 20%;
  margin-right: 20%;
}

@media screen and (max-width: 1200px) {
  div#blog-div {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media screen and (max-width: 900px) {
  div#blog-div {
    margin-left: 2%;
    margin-right: 2%;
  }
}

h2.blog-title {
  font-style: italic;
  text-align: right;
  color: rgb(255, 237, 188);
  font-family: "Fjalla One", sans-serif;
  font-size: 2em;
  margin-top: 3em;
  margin-bottom: 10px;
}

p.blog-date {
  font-family: "Raleway", sans-serif;
  text-align: right;
  font-size: 1em;
  color: white;
  font-weight: 100;
  margin-bottom: 2em;
}

div.blog-content {
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  text-align: justify;
  color: white;
  font-weight: 100;
  margin-bottom: 2em;
}

a.blog-links {
  color: rgb(255, 229, 159);
  transition: 0.5s;
}

a.blog-links:hover {
  color: white;
  text-decoration: none;
}

/* about */

div.about-content {
  font-weight: 100;
  margin-top: 6em;
  margin-bottom: 2em;
  margin-left: 25%;
  margin-right: 25%;
  font-size: 1em;
  font-family: "Raleway", sans-serif;
  color: white;
  text-align: left;
}

@media screen and (max-width: 1400px) {
  div.about-content {
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media screen and (max-width: 900px) {
  div.about-content {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5em;
  }
}

a#about-page-link {
  color: rgb(255, 229, 159);
  transition: 0.5s;
}

a#about-page-link:hover {
  color: white;
  text-decoration: none;
}

/* contact */

div.contact-details {
  font-weight: 100;
  margin-top: 10em;
  font-size: 1.3em;
  font-family: "Raleway", sans-serif;
  color: white;
  text-align: center;
}

@media screen and (max-width: 450px) {
  div.contact-details {
    margin-top: 4em;
  }
}
